@import "~bootstrap/scss/bootstrap";
@import "custom";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prata&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,opsz,wght@0,6..96,400..900;1,6..96,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vidaloka&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chonburi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chonburi&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chonburi&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Chonburi&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	background-color: $light_beige;
	scrollbar-gutter: stable;
	width: calc(100vw - 12px);
	height: calc(80vh - 12px);
	z-index: 1;
	overflow-x: hidden;
}

.pageContainer {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	row-gap: 0.5rem;
	position: relative;
}

.container {
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: 5rem;
}

.contentsContainer {
	display: flex;
	flex-direction: column;
}

::selection {
	background: $light_pink;
}

a {
	text-decoration: none;
}

/* -------- Scroll Bar -------- */
* {
	scrollbar-width: thin;
	scrollbar-color: #888 none;
}
::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* -------- Shared Theme -------- */
.backgroundLight {
	background-color: $light_beige;
}

.backgroundDark {
	// background-color: $green;
}

.fontLight {
	color: white;

	a {
		color: white;
	}
}

.fontDark {
	color: black;

	a {
		color: black;
	}
}

.fontPink {
	color: $mid_pink;
	a {
		color: $mid_pink;
	}
}

.fontBlack {
	color: black;
}

.fontMenu {
	font-family: $text_angkor;
	font-weight: 400;
}

.fontHighlight {
	font-family: $text_angkor;
	font-weight: 600;
	color: $mid_pink;
}

.fontFooter {
	font-family: $text_abril;
	font-weight: 600;
}

.fontWelcome {
	font-family: $text_angkor;
	font-weight: 700;
}

.fontHeader {
	font-family: $text_abril;
	font-weight: 600;
	font-size: 3rem;
}

.fontSubheader {
	font-family: $text_abril;
	font-weight: 600;
	font-size: 1.5rem;
}

.fontText {
	font-family: $text_roboto;
	font-weight: 400;
}

@media (max-width: $tablet) {
	.container {
		row-gap: 3rem;
	}

	.fontHeader {
		text-align: center;
	}
}

@media (max-width: $small-tablet) {
	.fontMenu {
		font-size: 1rem;
	}
}

@media (max-width: $phone) {
	.container {
		column-gap: 0rem;
		row-gap: 0rem;
	}
}
