@import "../../custom.scss";

.welcomeContainer {
	position: relative;
	top: 0;
	left: 0;
	min-width: 100vw;
	min-height: 100vh;
	z-index: 500;
}

.welcomeAnimationContainer {
	position: relative;
	top: 0;

	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow: hidden;

	.animationText {
		width: 100%;
		padding: 0;
		margin: 0;
		text-align: center;
		font-size: 15rem;
		color: $mid_beige;
		white-space: nowrap;
	}

	.animationText:not(:first-child) {
		margin-top: -7rem;
	}

	.animationText:nth-child(odd) {
		text-align: right;
	}

	.animationText:nth-child(even) {
		text-align: left;
	}
}

.welcomeTextContainer {
	position: absolute;
	top: 20%;
	left: 20%;
	font-size: 3.5rem;

	.highlight {
		color: $mid_pink;
	}
}

@media (max-width: $tablet) {
	.welcomeContainer {
		min-height: 50vh;
		max-height: 65vh;
	}

	.welcomeAnimationContainer {
		.animationText {
			font-size: 12rem;
		}
	}

	.welcomeTextContainer {
		position: absolute;
		top: 15%;
		left: 15%;
		font-size: 2.5rem;
	}
}

@media (max-width: $small-tablet) {
	.welcomeTextContainer {
		position: absolute;
		top: 25%;
		left: 15%;
		font-size: 2.2rem;
	}
}

@media (max-width: $phone) {
	.welcomeContainer {
		min-height: 65vh;
		max-height: 75vh;
	}

	.welcomeTextContainer {
		position: absolute;
		top: 35%;
		left: 10%;
		font-size: 2rem;
	}
}
