@import "../../custom.scss";

.aboutMeContainer {
	display: flex;
	flex-direction: row;
	column-gap: 3rem;

	vertical-align: middle;
	justify-items: center;
	justify-content: center;

	padding-top: 15rem;

	.aboutMeImageContainer {
		width: 45%;
		padding: 1rem;
		margin: auto;
		text-align: center;

		img {
			max-width: 60%;
			border-radius: 50%;
			border: 3.5px solid $mid_pink;
			transition: box-shadow 0.3s ease-in-out;
			margin: auto;

			&:hover {
				box-shadow: 0 0 2rem 1rem rgba($mid_pink, 0.6);
			}
		}
	}

	.aboutMeContentsContainer {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
		padding: 2rem;
		vertical-align: middle;
		justify-items: center;
		justify-content: center;

		width: 50%;

		.aboutMeContentsText {
			padding-bottom: 1rem;
		}
	}
}

.imageTextDecoration {
	span {
		position: absolute;
		top: 1310px;
		left: 495px;
		transform-origin: 0 185px;
	}
}

@media (max-width: $tablet) {
	.aboutMeContainer {
		display: flex;
		flex-direction: column;
		row-gap: 0rem;

		.aboutMeImageContainer {
			width: 55%;

			img {
				max-width: 70%;
			}
		}

		.aboutMeContentsContainer {
			margin: auto;
			width: 80%;
			row-gap: 3rem;
		}
	}
}

@media (max-width: $small-tablet) {
	.aboutMeContainer {
		.aboutMeImageContainer {
			width: 75%;
		}

		.aboutMeContentsContainer {
			margin: auto;
			width: 100%;
			row-gap: 3rem;
		}
	}
}

@media (max-width: $phone) {
}
