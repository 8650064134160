@import "../../custom.scss";

.contactsContainer {
	width: 100vw;
	margin-left: calc(-50vw + 50% - 6px);
	background-image: url(../../assets/contacts_backgroud.png);
	background-repeat: no-repeat;
	background-size: cover;

	display: flex;
	flex-direction: column;
	row-gap: 15rem;

	margin-top: 5rem;
}

.contactsContentContainer {
	margin-top: 25rem;
	padding-top: 5rem;

	.contactsHeader {
		font-size: 7rem;
	}
}

.contactsTable {
	display: flex;
	flex-direction: row;

	padding-top: 5rem;
	margin: auto;
	justify-content: space-evenly;
	width: 100%;

	.contactsTableCell {
		padding: 3rem 0;
		text-align: center;
		width: calc(100% / 4);
		text-align: center;
		vertical-align: middle;
		font-size: 1.2rem;

		&:hover {
			transform: scale(1.5);
			cursor: pointer;
		}
	}

	svg {
		margin: auto;
		transition: transform 0.3s ease-in-out;
	}
}

.blink {
	// display: inline-block;
	animation: scale-and-wobble 5s ease-in-out forwards;
}

@keyframes scale-and-wobble {
	0%,
	100% {
		transform: scale(1);
	}
	35% {
		transform: scale(1.5);
	}
	40%,
	42%,
	44%,
	46%,
	48%,
	50%,
	52%,
	54%,
	56%,
	58%,
	60% {
		transform: scale(1.5) rotate(-5deg);
	}
	41%,
	43%,
	45%,
	47%,
	49%,
	51%,
	53%,
	55%,
	57%,
	59%,
	61% {
		transform: scale(1.5) rotate(5deg);
	}
	62%,
	75% {
		transform: scale(1.5);
	}
}

@media (max-width: $tablet) {
	.contactsContentContainer {
		.contactsHeader {
			padding: 2rem;
			font-size: 6rem;
		}
	}

	.contactsTable {
		width: 80%;
		display: grid;
		grid-template-columns: 50% 50%;
		grid-row: auto auto;
		grid-column-gap: 1rem;
		grid-row-gap: 1rem;
		text-align: center;

		.contactsTableCell {
			margin: auto;
			width: 100%;
		}
	}
}

@media (max-width: $small-tablet) {
	.contactsContentContainer {
		.contactsHeader {
			padding: 1rem;
			font-size: 5rem;
		}
	}

	.contactsTable {
		width: 100%;
	}
}

@media (max-width: $phone) {
	.contactsContentContainer {
		.contactsHeader {
			padding: 1rem;
			font-size: 3rem;
		}
	}
}
