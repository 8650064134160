@import "../../custom.scss";

.navBar-fixed {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	margin: 0 auto;
	padding: 3rem;
	padding-right: 1rem;
	align-items: center;

	width: calc(100vw - 40px);
	height: 100%;

	transition: top 0.5s;
	z-index: 1000;

	&.hide {
		top: -100px;
	}
}

.navBar-popup {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	position: fixed;
	top: 1rem;
	left: 8vw;
	margin: 1rem auto;
	padding: 1rem;
	width: 85vw;
	background: rgb($mid_beige, 0.7);
	border-radius: 5rem;
	transition: transform 0.5s;
	z-index: 999;
	transform: translateY(-150%);

	&.show {
		margin: auto;
		transform: translateY(0%);
	}

	.logo {
		width: 15rem;
	}
}

.logoContainer {
	padding: 0 2rem;
}

.menuContainer {
	margin: auto 0;
	padding: 0;

	a {
		padding: 0 1rem;
		position: relative;
		text-decoration: none;

		&.active {
			font-weight: 700;
		}
		&.active::after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translateX(-50%);
			width: 10px; // Adjust size as needed
			height: 10px; // Adjust size as needed
			background-color: pink;
			border-radius: 50%;
		}
	}
}

@media (max-width: $tablet) {
	.navBar {
		padding-top: 1.5rem;
	}
}

@media (max-width: $small-tablet) {
	.logoContainer {
		display: none;
	}

	.menuContainer {
		margin: auto;
		justify-content: space-evenly;

		a {
			padding: 0 1.5rem;
		}
	}

	.navBar {
		padding-top: 1.3rem;

		.logo {
			width: 35px;
		}
	}

	.navBar-fixed {
		padding-right: 0rem;
	}
}

@media (max-width: $phone) {
	.navBar-fixed {
		display: none;
	}

	.navBar-popup {
		display: none;
	}

	.navBar {
		padding-left: 1.5rem;
		.logo {
			width: 28px;
		}
	}
}
