@import "../../custom.scss";

.modal-dialog {
	--bs-modal-width: 80vw !important;
	--bs-modal-height: 80vh !important;
}

.modal-open {
	padding-right: 0px !important;
}

.modal-header {
	.modal-title {
		font-size: 2rem;
	}
}

.modal-body {
	.modal-text {
		padding: 2rem;
	}

	.container {
		margin: auto;
		width: 100%;
	}
}

.modal-body {
	display: flex;
	flex-direction: row;
	column-gap: 1rem;

	.modalImage {
		width: 70%;
	}

	.modalText {
		width: 30%;
		padding: 2rem;
	}

	.carousel-item {
		img {
			width: fit-content !important;
			max-height: 50vh !important;
			margin: auto;
		}
	}
}

.projectsContainer {
	margin: 5rem;
	display: flex;
	flex-direction: column;
	row-gap: 2rem;

	padding-top: 10rem;
}

.carousel-item {
	&.active {
		margin: auto;
		text-align: center;
		display: flex !important;
		flex-direction: column !important;
		height: 55vh !important;
	}

	img {
		width: fit-content !important;
		max-height: 35vh !important;
		margin: auto;
	}

	img:hover {
		cursor: pointer;
	}

	.carousel-caption {
		color: black !important;
		position: relative !important;
		margin: 0;
		left: 0 !important;
		right: 0 !important;

		&:hover {
			cursor: pointer;
		}

		h3 {
			font-family: $text_abril;
			font-weight: 600;
			font-size: 1.5rem;
		}

		p {
			font-family: $text_roboto;
			font-weight: 400;
			font-size: 1.2rem;
		}
	}
}

.carousel-indicators {
	button {
		&.active {
			background-color: $mid_pink;
		}

		&:not(.active) {
			background-color: $mid_pink;
			opacity: 0.2;
		}
	}
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	filter: invert(60%) sepia(15%) saturate(50) hue-rotate(-80deg)
		brightness(100%) contrast(100%) !important;
}

@media (max-width: $tablet) {
	.modal-body {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;

		.modalImage {
			width: 100%;
		}

		.modalText {
			width: 80%;
			padding: 2rem;
		}

		.carousel-item {
			img {
				max-width: 55vw !important;
				height: auto !important;
			}
		}
	}
}

@media (max-width: $small-tablet) {
	.modal-body {
		.carousel-item {
			img {
				max-width: 50vw !important;
				height: auto !important;
			}
		}
	}
}

@media (max-width: $phone) {
	.modal {
		--bs-modal-width: 50vw !important;
		--bs-modal-height: 55vh !important;
	}

	.modal-dialog {
		--bs-modal-width: 50vw !important;
		--bs-modal-height: 55vh !important;
	}

	.projectsContainer {
		padding-top: 7rem;
	}

	.carousel-item {
		img {
			max-width: 70vw !important;
			height: auto !important;
			margin: auto;
		}
	}
}
