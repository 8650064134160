@import "../../custom.scss";

.footerContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	text-align: center;

	margin: auto;
	bottom: 0;
	padding: 2rem 0;
	padding-top: 0.8rem;

	height: 100%;
}

.footerRow {
	display: flex;
	flex-direction: row;

	.lineContainer {
		padding: 5px;
		margin: auto;
		text-align: center;

		.line {
			background-color: $light_beige;

			width: 1.5px;
			height: 15px;
		}
	}

	.footerItem {
		margin: auto;
		padding: 0 0.5rem;
		vertical-align: middle;

		.footerText {
			font-size: 1rem;
		}
	}
	.footerItem:not(:last-child) {
		// border-right: 1.2px solid $light_beige;
	}
}

.footerColumn {
	display: flex;
	flex-direction: column;
}

@media (max-width: $small-tablet) {
	.footerRow {
		.footerItem {
			.footerText {
				font-size: 0.8rem;
			}
		}
	}
}
@media (max-width: $phone) {
	.footerRow {
		flex-direction: column;

		.lineContainer {
			padding: 0.2rem;

			.line {
				width: 20px;
				height: 1.5px;
			}
		}

		.footerItem {
			.footerText {
				font-size: 0.7rem;
			}
		}
	}
}
