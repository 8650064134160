@import "../../custom.scss";

.buttonCustom {
	border: 1px solid black;
	background-color: $light_beige;
	padding: 5px;
	width: fit-content;
}

.light {
	color: black;
	font-weight: 500;
}
.light:hover {
	cursor: pointer;
	background-color: black;
	color: white;
}

.dark {
	background-color: black;
	color: white;
}
.dark:hover {
	cursor: pointer;
	background-color: $light_beige;
	color: black;
}

@media (max-width: $tablet) {
}

@media (max-width: $small-tablet) {
}

@media (max-width: $phone) {
}
