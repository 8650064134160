@import "../../custom.scss";

.flipContainer {
	perspective: 1000px;

	&.hovered .flipTextContainer {
		transform: rotateX(180deg);
	}
}

.flipTextContainer {
	transition: 0.3s;
	transform-style: preserve-3d;
	position: relative;
	margin: 0.5rem;
}

.front,
.back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.front {
	transform: rotateX(0deg);
	z-index: 2;
}

.back {
	transform: rotateX(-180deg);
}

@media (max-width: $tablet) {
}

@media (max-width: $small-tablet) {
}

@media (max-width: $phone) {
}
